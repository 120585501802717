import React, { useState } from "react"
import Joi from "@hapi/joi"
import axios from "axios"

const FormErrorAlert = ({ children, show }) => {
  return (
    <>
      {show ? (
        <div className="uk-alert-danger" uk-alert>
          <p>{children}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const showThanksModal = () => {
  const element = document.getElementById("modal-example")
  const UIkit = window.UIkit
  if (UIkit) {
    UIkit.modal(element).show()
  }
}

const AskEmmForm = ({ afterFormSubmit }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [about, setAbout] = useState("")
  const [question, setQuestion] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [aboutError, setAboutError] = useState(false)
  const [questionError, setQuestionError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const schema = Joi.object({
      name: Joi.string().min(3).max(30).required(),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: false } })
        .required(),
      about: Joi.string().required(),
      question: Joi.string().min(3).max(30).required(),
    })

    const validation = schema.validate({ name, email, question, about })

    if (validation.error) {
      // console.log(validation);
      if (!name || validation.error.details[0].path == "name") {
        setNameError(true)
      }

      if (!email || validation.error.details[0].path == "email") {
        setEmailError(true)
      }

      if (!about || validation.error.details[0].path == "about") {
        setAboutError(true)
      }

      if (!question || validation.error.details[0].path == "question") {
        setQuestionError(true)
      }
    } else {
      axios
        .post("https://www.admin-emarsmission.aspgulf.net/ask-emm-responses", {
          name,
          email,
          question,
          about,
        })
        .then(response => {
          setNameError(false)
          setQuestionError(false)
          setAboutError(false)
          setEmailError(false)
          setName("")
          setEmail("")
          setQuestion("")
          setAbout("")

          showThanksModal()
        })
        .catch(err => {
          //   console.log(err)
        })
    }
  }

  return (
    <form
      className="uk-form-horizontal uk-margin-large dark-form"
      onSubmit={handleSubmit}
    >
      <div className="uk-margin">
        <label className="uk-form-label" for="name">
          NAME
        </label>

        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <FormErrorAlert show={nameError}>
            Please provide a valid name.
          </FormErrorAlert>
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" for="email">
          EMAIL
        </label>

        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="form-horizontal-text"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <FormErrorAlert show={emailError}>
            Please provide a valid email.
          </FormErrorAlert>
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" for="question-about">
          QUESTION ABOUT
        </label>

        <div className="uk-form-controls">
          <select
            className="uk-select"
            id="question-about"
            value={about}
            onChange={e => setAbout(e.target.value)}
          >
            <option>SELECT SUBJECT HERE..</option>
            <option>MARS</option>
            <option>EXPLORATION</option>
            <option>MISSION</option>
            <option>HOPE PROBE</option>
          </select>

          <FormErrorAlert show={aboutError}>
            Please select an option.
          </FormErrorAlert>
        </div>
      </div>
      <div className="uk-margin">
        <label className="uk-form-label" for="form-horizontal-text">
          QUESTION
        </label>

        <div className="uk-form-controls">
          <textarea
            className="uk-textarea"
            rows="5"
            value={question}
            onChange={e => setQuestion(e.target.value)}
          ></textarea>
          <FormErrorAlert show={questionError}>
            Please write down your question.
          </FormErrorAlert>
        </div>
      </div>
      <div className="uk-margin uk-text-right">
        <button className="uk-button uk-button-default" type="submit">
          Submit
        </button>
      </div>
    </form>
  )
}

export default AskEmmForm
