import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgAskEmmCover from "../assets/images/ask-emm-bg.png"
import AskEmmForm from "../components/forms/ask-emm-form"

const AskEmmPage = () => (
  <Layout>
    <SEO title="Ask Us" />
    <div id="modal-example" className="uk-flex-top">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ background: "none", width: "auto" }}>
        {/* <button className="uk-modal-close-outside" type="button" uk-close>CLOSE</button> */}
        <h2 className="relative">
          THANK YOU FOR YOUR QUESTION
                    <div className="text-underline-heavy"></div>
        </h2>
        <p className="text-white">Our team will get back to you with the answers you are looking for within 2-3 weeks.</p>
      </div>
    </div>
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        <img src={imgAskEmmCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{ marginTop: "-300px", position: "relative" }}>
        <div className="standard-container-p0 pv4 ph5">
          <h2 className="relative">
            ASK US
                    <div className="text-underline-heavy"></div>
          </h2></div>
        <div className="large-container pb6">
          <div className="standard-container standard-bg">
            <h3 className="uk-text-uppercase text-highlight-brow">
              Got a question about the red planet <br /> or the Emirates Mars Mission?
              </h3>
            <p> Submit your questions in the form below and ​our team will get back to you.</p>
            <div className="mt5">
              <AskEmmForm></AskEmmForm>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default AskEmmPage
